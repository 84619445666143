
.div-des{
    width:60%;
    margin:auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.div-part{
    flex-basis: 48%;
    text-align: center;
    border-radius: 7px;
    margin-bottom: 20PX;
    color: white;
    position:relative;
}

.div-part img{
    border-radius: 7px;
    width:100%;
}

.overlay{
    left:0;
    top: 0;
    width:100%;
    height:100%;
    position: absolute;
    border-radius: 7px;
    margin-bottom:20px;
    background:linear-gradient(rgba(0,0,0,0.5),#476D81);
    opacity: 0;
    transition: 0.7s;
}

.div-part:hover .overlay{
    opacity: 1;
}

.description{
    width: 80%;
    text-align: left;
    bottom:10%;
    left: 50%;
    transform:translateX(-50%);
    position: absolute;
    opacity: 0;
}

.description h4{
    font-size: 25px;
}



.hr{
    height: 2px;
    padding:2px auto;
    border:0;
    width:60%;
}
.div-part:hover .description{
    opacity: 1;
}


@media screen and (max-width:600px) {
    .div-des{
        flex-direction: column;
        width:95%;
    }

    .description{
        bottom:10%;
    }

}