.latest {
    position: relative;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6)), url('../../../assets/visa-solution.jpeg');
    background-position: center;
    background-size: cover;
    height: auto;
    margin-top: 5vh;
    /* margin-bottom: 5vh; */
}

.custom-bg{
    background: linear-gradient(to bottom,rgba(71, 109, 129, 0.5),rgba(255,255,255,0.3));
    border-radius: 5px;
    width: 100%;
    padding: 20px 30px;
}

.latest-container{
    max-width: 1300px;
    margin: auto;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.content {
    position: relative;
    z-index: 1;
    padding: 20px;
}

.form-group {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 5px;
    color: white;
}

input[type="text"],
input[type="email"],
input[type="number"],
textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.bttn{
    padding: 10px 20px;
    background-color: #476D81;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.bttn:hover {
    background-color: #305664;
}
