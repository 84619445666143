.type_holder{
    background: linear-gradient(to left,rgba(202,133,6,0.6),rgba(143, 201, 143,0.7)),url('../../../assets/visa-solution.jpeg');
    height: 40vh;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.info-box{
    width: 100%;
    height: 200px;
    background-color: #476D81;
    padding: 12px;
    transition: padding 0.3s;
}

.info-box:hover {
    padding: 9px;
    text-align: justify;
    background-color: #273a44;
  }

@media screen and (max-width:550px) {
    .type_holder{
        height: auto;
    }
}