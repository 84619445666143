.overlay1{
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(to right ,rgba(0,0,0,0.5),rgba(104, 105, 105,0.6));
}

.text-holder{
    width: 100%;
    background: rgba(0,0,0,.6);
    border-radius: 7px;
    padding: 60px 190px;
    margin: 35px auto;
    color: white;
    text-align: center;
    position:  relative ;
}

.butun{
    padding: 15px 30px;
    width: 220px;
    margin-top: 20px;
    background-color: white;
    border: none;
    color: #476D81;
    font-weight: 900;
    border-radius: 40px;
    transition: .5s ease-in-out;
}

.butun:hover{
    border-radius: 50px;
    background-color: #476D81;
    color: #fff;
}

@media screen and (max-width:1390px) {
    .text-holder{
        width: 100%;
        background: rgba(0,0,0,.6);
        border-radius: 7px;
        padding: 30px 160px;
    }
}


@media screen and (max-width:450px) {
    .heading span{
        font-size: 30px;
    }
    
    .text-holder{
        padding: 20px 5px;
        margin: 25px;
        text-align: center;
    }

    .banner-description{
        font-size: 14px;
    }

    .butun{
        padding: 5px;
        width: 100px;
        margin-top: 20px;
        border: none;
        color: #476D81;
        font-weight: 900;
        border-radius: 4px;
    }
}


@media screen and (max-width:325px) {
    .icon-holder{
        top: 1vh;
    }
    .text-holder{
        text-align: center;
    }
    
    .text-holder p{
        font-size: 12px;
    }
}